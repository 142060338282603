@import "~/frontend/scss/stage0";

$primary: #2e3692;
$secondary: #d02b22;
$enable-gradients: true;
$gradient: linear-gradient(180deg, rgba(#000, 0), rgba(#000, 0.2)) !default;

@import "~/frontend/scss/stage1";

// dropdown
$dropdown-dark-bg: $secondary;
$dropdown-dark-link-color: $light;
// navbar
$navbar-light-toggler-border-color: $light;
$navbar-dark-toggler-border-color: $dark;

@import "~/frontend/scss/stage2";
